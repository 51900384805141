import { findAddress1Coordinate, getSessionStorage } from "@vhows/util";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { defaultMapCenter, district1CoordinateAtom, mapAddressAtom, mapCenterAtom } from "store/global";

function useMapAddress() {
  const router = useRouter();
  const [mapAddress, setMapAddress] = useAtom(mapAddressAtom);
  const [, setMapCenter] = useAtom(mapCenterAtom);
  const [, setEntryMapCenter] = useAtom(district1CoordinateAtom);
  const defaultCoordinate = { latitude: defaultMapCenter.lat, longitude: defaultMapCenter.lng };

  useEffect(() => {
    getAddress();
  }, [router.isReady]);

  const getAddress = async () => {
    const address = getSessionStorage("address");
    if (address) {
      setMapCenter({ lat: address.latitude, lng: address.longitude });
      setMapAddress({
        district_1: address.district_1,
        district_2: address.district_2,
        district_3: address.district_3,
      });
      const initMapCenter = await findAddress1Coordinate(address.district_1, defaultCoordinate);
      setEntryMapCenter({ lat: initMapCenter.latitude, lng: initMapCenter.longitude });
    }
  };
  return mapAddress;
}

export default useMapAddress;
