import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { PageProps } from "pages/_app";
import { useAtom } from "jotai";
import { map } from "lodash-es";
import Image from "next/image";
import { defaultMapCenter, loadingAtom, mapAddressAtom, mapCenterAtom } from "store/global";
import { getCompanies, INDICATORS, loggingPM } from "service/vhows";
import { Modal } from "../modal";
import { BlurringImage } from "ui";
import { getLocalStorage, setDataLayer, setSessionStorage } from "@vhows/util";
import { COMMON, ENTRY_TYPE } from "@constants/common";
import useMapAddress from "components/pesticide-company/useMapAddress";

const Company = ({ company, index, entryType }: PageProps) => {
  const router = useRouter();
  const address1 = router.query.address1 as string;
  const address2 = router.query.address2 as string;
  const address3 = router.query.address3 as string;
  const [mapAddress] = useAtom(mapAddressAtom);

  const linkPath = {
    pathname: `${process.env.NEXT_PUBLIC_FACIL_URL}/detail/${company.id}`,
    query:
      !!address1 && !!address2 && !!address2
        ? {
            type: "thepesticide",
            address1: address1,
            address2: address2,
            address3: address3,
            uuid: getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID),
            peatId: getLocalStorage("profile-screen-enter")?.peat_id,
            diseaseName: getLocalStorage("profile-screen-enter")?.disease_name,
            crop: getLocalStorage("profile-screen-enter")?.crop,
            source: getLocalStorage("profile-screen-enter")?.source,
          }
        : {
            type: "thepesticide",
            address1: mapAddress?.district_1,
            address2: mapAddress?.district_2,
            address3: mapAddress?.district_3,
            uuid: getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID),
            peatId: getLocalStorage("profile-screen-enter")?.peat_id,
            diseaseName: getLocalStorage("profile-screen-enter")?.disease_name,
            crop: getLocalStorage("profile-screen-enter")?.crop,
            source: getLocalStorage("profile-screen-enter")?.source,
          },
  };

  const handleRouterChange = () => {
    setSessionStorage("entryScrollPosition", window.scrollY);
    if (entryType === ENTRY_TYPE.DETAIL) setSessionStorage(`scroll-y-${ENTRY_TYPE.DETAIL}`, window.scrollY);
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    setDataLayer({
      event:
        entryType === ENTRY_TYPE.DETAIL
          ? "pest_resultDetail_nearPrescriptionList_click"
          : entryType === ENTRY_TYPE.PESTICIDE
          ? "pest_pesticideDetail_nearPrescriptionList_click"
          : "pest_diagnosis_main_nearprescription_list_click",
      user_uuid: userKey,
      user_address: `${mapAddress?.district_1} ${mapAddress?.district_2} ${mapAddress?.district_3}`,
      prescription_name: company.companyName,
      prescription_id: company.id,
      prescription_address: company?.address?.road,
    });
    router.push(linkPath);
  };

  const handleDataLayer = () => {
    const id = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    setDataLayer({
      event:
        entryType === ENTRY_TYPE.DETAIL
          ? "pest_resultDetail_prescriptionCallButton_click"
          : entryType === ENTRY_TYPE.PESTICIDE
          ? "pest_pesticideDetail_callButton_click"
          : "pest_main_nearPrescription_callButton_click",
      user_uuid: id,
      user_address: `${mapAddress?.district_1} ${mapAddress?.district_2} ${mapAddress?.district_3}`,
      prescription_name: company.companyName,
      prescription_id: company.id,
      prescription_address: company?.address?.road,
    });
    const jsonData = JSON.stringify({
      user_uuid: id,
      user_address: `${mapAddress?.district_1} ${mapAddress?.district_2} ${mapAddress?.district_3}`,
      user_name: "", //(팜모닝DB연결필요)
      user_phoneNumber: "", //(팜모닝DB연결필요)
      store_name: company.companyName,
      store_id: company.id,
    });
    const entry =
      entryType === ENTRY_TYPE.DETAIL
        ? INDICATORS.PESTICIDE_COMPANY_CALL_FROM_DETAIL
        : entryType === ENTRY_TYPE.PESTICIDE
        ? INDICATORS.PESTICIDE_COMPANY_CALL_FROM_PESTICIDE_DETAIL
        : INDICATORS.PESTICIDE_COMPANY_CALL_FROM_MAIN;
    loggingPM(entry, jsonData, id);
  };

  const call = () => {
    return (
      <div className="mt-1">
        {company?.phoneNumber || company?.phoneNumber2 ? (
          <a
            className="mt-2 flex w-fit cursor-pointer justify-center rounded-md bg-[#0BB25F] py-[4.5px] px-3 text-center  text-[15px] text-white"
            href={`tel:${company.bizcallNumber || company.phoneNumber || company.phoneNumber2}`}
            onClick={handleDataLayer}
          >
            <Image
              src={"/icons/phone.svg"}
              alt="phone"
              width="16"
              height="18"
              className="inline-block text-white"
            ></Image>
            <span className="ml-[7px]">전화 상담</span>
          </a>
        ) : (
          <div className="mt-2 flex w-fit justify-center rounded-md bg-[#0BB25F] py-1 px-3 text-center  text-[15px] text-white">
            <Image
              src={"/icons/phone.svg"}
              alt="phone"
              width="16"
              height="18"
              className="inline-block text-white"
            ></Image>
            <span className="ml-[7px]">전화 상담</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={`cursor-pointer border-b border-[#F0F2F5] last:border-0 py-4 mx-4 ${
          company?.images.find((val: any) => val.category === 1) ? "flex justify-between" : ""
        }`}
      >
        <div className="grow">
          <a
            onClick={handleRouterChange}
            // data-gtm={
            //   entryType === ENTRY_TYPE.DETAIL
            //     ? "pest_resultDetail_nearPrescriptionList_click"
            //     : entryType === ENTRY_TYPE.PESTICIDE
            //     ? "pest_pesticideDetail_nearPrescriptionList_click"
            //     : "pest_diagnosis_main_nearprescription_list_click"
            // }
          >
            <div className="mr-3">
              <div className="flex items-center">
                <h2 className="text-[17px] font-medium">{company?.companyName}</h2>
                <span className="ml-1 text-[15px] font-medium text-[#65666B]">
                  {Number(company?.distance).toFixed(1)}km
                </span>
              </div>

              <div className="mt-1 text-[#65666B]">
                {company?.address?.road ? (
                  <span className="text-[15px]">{company?.address?.road}</span>
                ) : (
                  <>
                    <span className="mr-2 inline-block rounded-[4px] border border-[#C3C5C9] py-[3.5px] px-1.5 text-[13px] leading-[13px] text-[#8B8D94]">
                      지번
                    </span>
                    <span className="inline-block text-[15px] text-[#8B8D94]">{company?.address?.jibeon || "-"}</span>
                  </>
                )}
              </div>
            </div>
          </a>
          {call()}
        </div>
        {company?.images.length && company?.images.find((val: any) => val.category === 1) ? (
          <a onClick={handleRouterChange}>
            <div className="relative w-[108px] h-[108px] rounded-lg border border-[#EDEFF2]">
              <BlurringImage
                className="rounded-lg object-cover"
                layout="responsive"
                priority={true}
                width={"100%"}
                height={"100%"}
                sizes="(min-width: 576px) 176px, 30vw"
                src={`${company?.images.find((val: any) => val.category === 1)?.imageUrl}`}
                alt="represent"
              />
            </div>
          </a>
        ) : null}
      </div>
    </>
  );
};

type Props = {
  id?: string;
  titleText?: string;
  moreText?: string;
  entryType?: string;
};

function PesicideCompany({ id, titleText = "주변 농약사", moreText = "더 보기", entryType }: PageProps & Props) {
  const [companies, setCompanies] = useState([]);
  const router = useRouter();
  // 팜모닝및 ERP에서 넘어왔을 경우, 유저 아이디 정보
  let userId = router.query.uuid as string;
  let type = router.query.type as string;
  const c = router.query.type as string;
  const defaultCoordinate = { latitude: defaultMapCenter.lat, longitude: defaultMapCenter.lng };
  const address1 = router.query.address1 as string;
  const address2 = router.query.address2 as string;
  const address3 = router.query.address3 as string;
  const mapAddress = useMapAddress();
  const [mapCenter] = useAtom(mapCenterAtom);

  useEffect(() => {
    if (mapCenter.lat && mapCenter.lng) {
      fetchCompanies();
    }
  }, [mapCenter.lat, mapCenter.lng]);

  /**
   * 농약사 데이터 가져오기
   */
  const fetchCompanies = async () => {
    const result = await getCompanies({
      pageNum: 1,
      pageLimit: 3,
      latitude: mapCenter.lat,
      longitude: mapCenter.lng,
      sort: { order: 0, type: 1 },
      filter: { publicable: [2] },
    });
    setCompanies(result.data.slice(0, 3));
  };

  const handleRouterChange = () => {
    entryType === ENTRY_TYPE.DETAIL
      ? setSessionStorage(`scroll-y-${ENTRY_TYPE.DETAIL}`, window.scrollY)
      : entryType === ENTRY_TYPE.PESTICIDE
      ? setSessionStorage(`scroll-y-${ENTRY_TYPE.PESTICIDE}`, window.scrollY)
      : setSessionStorage("entryScrollPosition", window.scrollY);
    router.push(linkPath);
  };

  const linkPath = {
    pathname: `${process.env.NEXT_PUBLIC_FACIL_URL}/list`,
    query:
      !!address1 && !!address2 && !!address2
        ? {
            type: "nearpesticide",
            address1: address1,
            address2: address2,
            address3: address3,
            uuid: getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID),
            peatId: getLocalStorage("profile-screen-enter")?.peat_id,
            diseaseName: getLocalStorage("profile-screen-enter")?.disease_name,
            crop: getLocalStorage("profile-screen-enter")?.crop,
            source: getLocalStorage("profile-screen-enter")?.source,
          }
        : {
            type: "nearpesticide",
            address1: mapAddress?.district_1,
            address2: mapAddress?.district_2,
            address3: mapAddress?.district_3,
            uuid: getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID),
            peatId: getLocalStorage("profile-screen-enter")?.peat_id,
            diseaseName: getLocalStorage("profile-screen-enter")?.disease_name,
            crop: getLocalStorage("profile-screen-enter")?.crop,
            source: getLocalStorage("profile-screen-enter")?.source,
          },
  };
  return (
    <>
      <div id={id} className="relative">
        {/*주변 농약사*/}
        <div className="mt-8">
          <div className="mx-4 flex flex-wrap items-center">
            <h2 className="text-[19px] text-[#1F2024] font-bold leading-6 inline-flex items-center">{titleText}</h2>
          </div>
          <div id="" className="w-full">
            {companies.length ? (
              map(companies, (val: any, idx: number) => {
                return <Company key={val?.id} company={val} index={idx} entryType={entryType} />;
              })
            ) : (
              <div className="h-[396.29px]"></div>
            )}
          </div>
        </div>

        <div
          id="next-inner"
          data-gtm={
            entryType === ENTRY_TYPE.DETAIL
              ? "pest_resultDetail_viewAllPrescriptionButton_click"
              : entryType === ENTRY_TYPE.PESTICIDE
              ? "pest_pesticideDetail_viewNearPrescriptionButton_click"
              : "pest_diagnosis_main_nearprescription_moreviewbutton_click"
          }
          onClick={handleRouterChange}
          className="min-w-max max-w-2xl bg-white mt-4 px-4 w-full"
        >
          <button
            id="memo-next-btn"
            className="rounded-[8px] border border-[#DCDFE3] text-[15px] text-[#1F2024] font-medium w-full h-[40px]"
          >
            {moreText}
          </button>
        </div>
      </div>
    </>
  );
}

export default PesicideCompany;
